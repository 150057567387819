import { Controller } from "@hotwired/stimulus"
import Clipboard from 'clipboard';

export default class extends Controller {
  connect() {
    let message = this.data.get('message') || "Copied!"

    let options = {
      'container': $(this.element).parents('.modal[role=dialog]')[0],
    };

    if(this.element.hasAttribute('data-input-copy-text')) {
      options['text'] = (trigger) => this.element.getAttribute('data-input-copy-text');
    } else {
      options['target'] = (trigger) => trigger.previousElementSibling || trigger;
    }

    let clipboard = new Clipboard($(this.element).find('button')[0], options);

    clipboard.on('success', ()=>{
      Notify.success(message)
    })
  }
}
